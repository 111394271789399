import React from "react"
// import BlockContent from "@sanity/block-content-to-react"

/* Import Global Hook(s) */
import { useVisitPage } from "~queries/hooks"
import { useHomePage } from "~queries/hooks"

/* Import Global Component(s) */
import BlockContent from "~components/blockContent/blockContent"
import Footer from "~components/footer/footer"
import Page from "~components/page/page"
import PageHeader from "~components/page/components/pageHeader/pageHeader"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import ImageGrid from "~components/imageGrid/imageGrid"
import Slideshow from "~components/slideshow/slideshow"
import BlockMatrix from "~components/global/blockMatrix"
import Map from "~components/map/map"

import TicketsLink from "~components/pages/index/components/ticketsLink/ticketsLink"



/* Import Page Components */
import InformationList from "~components/pages/visit/components/informationList/informationList"

/* Import Local Style(s) */
import "./visit.scss"

const Visit = ({ location }) => {
  const { pageTitle, _rawHeaderCopy, visitInformations, slideshow, visitBlocks } =
    useVisitPage()

  const {
    displayTickets,
  } = useHomePage()

  return (
    <Page title={pageTitle} location={location}>
      <PageHeader title={pageTitle} location={location} />
      <PageArticle title={pageTitle} className="visit">
        <PageArticleSection className="visit">
          <div className="visit-description">
            <BlockContent blocks={_rawHeaderCopy} />
          </div>

          {displayTickets && <TicketsLink />}

          {slideshow.images.length > 0 ? (
            <div className="desktop-only"><ImageGrid images={slideshow.images} /></div>
          ) : null}

          {slideshow.images.length > 0 ? (
            <div className="mobile-only"><Slideshow slideshow={slideshow} /></div>
          ) : null}

          {visitBlocks && visitBlocks.length > 0 && <BlockMatrix blocks={visitBlocks} />}

          <InformationList information={visitInformations} />

          <div className="mt--1"></div>

          <Map />
        </PageArticleSection>
      </PageArticle>
      <Footer />
    </Page>
  )
}

export default Visit
